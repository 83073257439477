import * as React from "react";
import { Helmet } from "react-helmet";
import {
  Page,
  PageSection,
  Grid,
  GridItem,
  Card,
  CardTitle,
  CardBody,
  CardHeader,
  CardHeaderMain,
  Brand,
  Nav,
  NavList,
  NavItem,
} from "@patternfly/react-core";

class LearnMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false,
    };
  }

  render() {
    const onPageResize = (props: {
      mobileView: boolean;
      windowSize: number;
    }) => {
      this.setState({ isMobileView: props.mobileView });
    };

    let introSectionSpan = 6;
    let cardColSpan = 4;
    let sidebarTocSpan = 3;
    let mainContentSpan = 9;
    if (this.state.isMobileView === true) {
      introSectionSpan = 12;
      cardColSpan = 12;
      sidebarTocSpan = 12;
      mainContentSpan = 12;
    }

    // Define intro content
    const introContent = {
      title: "Welcome to Red Hat Architecture Center",
      description:
        "Designed for architects and IT leaders, the Red Hat Architecture Center provides practical patterns and designs to solve real-world challenges. Explore how the Red Hat Portfolio supports your cloud adoption, accelerates application modernization, and showcases joint partner solutions to help you build scalable, innovative IT infrastructures.",
      videoEmbed: `
        <!--ARCADE EMBED START-->
        <div style="position: relative; padding-bottom: calc(56.67989417989418% + 41px); height: 0; width: 100%;">
          <iframe src="https://demo.arcade.software/hu3CF0tmFTK0lOOIxMJn?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true" 
                  title="Red Hat Architecture Center Walkthrough" 
                  frameborder="0" 
                  loading="lazy" 
                  webkitallowfullscreen 
                  mozallowfullscreen 
                  allowfullscreen 
                  allow="clipboard-write" 
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; color-scheme: light;">
          </iframe>
        </div>
        <!--ARCADE EMBED END-->
      `,
    };
    

    // Define sections and cards
    const sections = [
      {
        id: "_buildingPA",
        title: "Solution Architecture",
        subtitle: "High-level designs based on proven customer experiences",
        link: "?productTypes=PA",
        cards: [
          {
            image: "/architect/portfolio/images/pa-overview-evidence.png",
            title: "Evidence-based architecture reference",
            description:
              "Built on facts found in successful customer implementations and documented deployments. They contain logical, schematic, and detailed diagrams on each of the technical components.",
          },
          {
            image: "/architect/portfolio/images/pa-overview-best-pattern.png",
            title: "Combination of recommended practices and patterns",
            description:
              "Our architectures are based on deployment scenarios and business drivers, and provide recommendations, lessons learned, and best practices on how to use Red Hat products to architect solutions.",
          },
          {
            image: "/architect/portfolio/images/pa-overview-tools.png",
            title: "Tools to help you create and architect your deployment",
            description:
              "Use our resources and tools to build your own architecture diagrams with standardized icons for better team collaboration and communication, and submit your ideas to a community of Red Hat experts who can provide feedback and guidance.",
          },
        ],

        subSection: {
          id: "_paprocess",
          title: "How Solution Architecture are created",
          cards: [
            {
              title: "Review Submissions",
              description:
                "Submissions come from the community, partners, and customers, and from Red Hatters who work with architects and engineers on their deployment implementations.",
            },
            {
              title: "Genericize Technology",
              description:
                "We take specific deployment scenarios and examine all products and parts of the solution. This leads us to build a generic architecture that fits many customer and industry needs, and allows for further customization with our open source software.",
            },
            {
              title: "Outline and Complete Architecture",
              description:
                "With a generic solution gathered and researched, we then design best practices to optimize the architecture to fit specific business objectives. The final published Solution Architecture ensures consistency in product scope and usage across deployment scenarios, with expert guidance for successful implementations.",
            },
          ],
        },
      },

      {
        id: "_interactiveVideoDemo",
        title: "Interactive Video Demo",
        subtitle:
          "Step-by-step demos to simplify and explore Red Hat technologies.",
        link: "?productTypes=Demo",
        cards: [
          {
            image: "/architect/portfolio/images/contribute_pa_create_01.png",
            title: "Engaging, Guided Walkthroughs",
            description:
              "Interactive Video Demos offer an engaging way to explore technical solutions. With step-by-step guidance, point-and-click interactions, and audio narratives, these demos simplify complex technologies and help you focus on the most relevant aspects of the solution.",
          },
          {
            image: "/architect/portfolio/images/contribute_pa_create_02.png",
            title: "Aligned with Business and Technical Needs",
            description:
              "Designed to address both technical and business challenges, these demos combine technical depth with accessible narratives. They provide valuable guidance to help you align solutions with your unique business goals.",
          },
          {
            image: "/architect/portfolio/images/contribute_pa_create_03.png",
            title: "Comprehensive Learning Resources",
            description:
              "Each demo includes additional resources such as training links, certifications, assessments, and follow-up actions to ensure you have everything you need to take the next step and fully leverage Red Hat technologies to achieve your goals.",
          },
        ],
      },

      {
        id: "_solutionPattern",
        title: "Solution Pattern",
        subtitle:
          "Modular patterns with sample apps that bridge high-level designs and production-ready architectures.",
        link: "?productTypes=SP",
        cards: [
          {
            image: "/architect/portfolio/images/whatisSP_01.png",
            title: "Opinionated practices",
            description:
              "Opinionated practices on how to design, develop, integrate, automate, and deliver cloud-native applications. They target common use cases for organizations adopting digital transformation.",
          },
          {
            image: "/architect/portfolio/images/whatisSP_02.png",
            title: "Reference implementation",
            description:
              "Every solution pattern contains a fully working reference implementation that illustrates the use case. Use it for demos or as a foundation for enablement activities.",
          },
          {
            image: "/architect/portfolio/images/whatisSP_03.png",
            title: "Fully documented",
            description:
              'The solution patterns are fully documented, including background information, technical diagrams, walkthrough guides, provisioning instructions, and automation scripts.  <a href="https://www.solutionpatterns.io/" target="_blank">Visit Solution Patterns website</a>',
          },
        ],
      },
      {
        id: "_validatedPattern",
        title: "Validated Pattern",
        subtitle: "Full, tested multi-product patterns ready for production.",
        link: "?productTypes=VP",

        cards: [
          {
            image: "/architect/portfolio/images/whatisVP_01.png",
            title: "Code you can run",
            description:
              "Validated Patterns include all the code you need to help build your edge stack, enabling faster POCs with fully automated deployments through GitOps.",
          },
          {
            image: "/architect/portfolio/images/whatisVP_02.png",
            title: "Dynamic and updated",
            description:
              "Continuously tested against current product releases, keeping your deployment up-to-date with the latest capabilities while reducing risks.",
          },
          {
            image: "/architect/portfolio/images/whatisVP_03.png",
            title: "Open and customizable",
            description:
              'Validated Patterns are foundational architectures that you can customize to meet your specific needs. Community contributions ensure continuous innovation. <a href="https://validatedpatterns.io/" target="_blank">Visit Validated Patterns website</a>',
          },
        ],
      },
      {
        id: "_other",
        title: "Other Resources",
        subtitle:
          "Provide feedback to improve patterns, tools, and the Architecture Center",
        cards: [
          {
            image: "/architect/portfolio/images/contribute_other_02.png",
            title: "Feedback for Content",
            description:
              'Have suggestions or contributions for existing Architectures and Demos? Submit feedback or code contributions through our <a href="https://gitlab.com/osspa/portfolio-architecture-examples/-/issues/new?issuable_template=Architecture_Feedback" target="_blank">issue tracker</a>. Explore our <a href="https://gitlab.com/osspa/portfolio-architecture-examples" target="_blank">catalog</a> for details and help us improve published solutions.',
          },
          {
            image: "/architect/portfolio/images/contribute_other_03.png",
            title: "Feedback for Tooling and Website",
            description:
              'Help improve our tools and the Red Hat Architecture Center website. Share your feedback for the diagramming tool <a href="https://gitlab.com/osspa/osspa-tool/-/issues/new" target="_blank">here</a>, or for the website <a href="https://gitlab.com/osspa/osspa-site/-/issues/new?issuable_template=site_suggestions" target="_blank">here</a>. Your insights are valuable!',
          },
          {
            image: "/architect/portfolio/images/pa-overview-tools.png", // Add a relevant image
            title: "Contact Our Team",
            description:
              'Have specific questions or need assistance? Reach out to our team directly at <a href="mailto:architecture@redhat.com">architecture@redhat.com</a>. We’re here to help!',
          },
        ],
      },
    ];

    // Sidebar links
    const sidebarLinks = [
      { id: "_buildingPA", text: "Solution Architecture" },
      { id: "_interactiveVideoDemo", text: "Interactive Video Demo" },
      { id: "_solutionPattern", text: "Solution Pattern" },
      { id: "_validatedPattern", text: "Validated Pattern" },
      { id: "_other", text: "Other Resources" },
    ];
    

    return (
      <Page id="learnmore" onPageResize={onPageResize}>
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Red Hat Architecture Center - Learn More Page</title>
          <meta
            name="description"
            content="Explore Red Hat Architecture Center for proven patterns, solutions, and tools to support your cloud adoption, app modernization, and IT innovation."
          />
          <meta
            name="keywords"
            content="Red Hat, Architecture Center, cloud adoption, application modernization, patterns, validated patterns, solution patterns"
          />
          <meta name="author" content="Red Hat" />
          <meta name="robots" content="index, follow" />
        </Helmet>
{/* Intro Section */}
<PageSection variant="light" className="intro-section full-width">
  <Grid hasGutter>
    <GridItem span={introSectionSpan}>
      <div className="intro-text">
        <h1>{introContent.title}</h1>
        <p>{introContent.description}</p>
      </div>
    </GridItem>
    <GridItem span={introSectionSpan}>
      <div
        className="responsive-video"
        style={{  marginBottom: "20px" }} // Add margin-bottom here
        dangerouslySetInnerHTML={{ __html: introContent.videoEmbed }}
      ></div>
    </GridItem>
  </Grid>
</PageSection>


        {/* Page Content with Sidebar */}
        <div className="page-content">
          <Grid hasGutter>
            {/* Sidebar */}
            <GridItem span={sidebarTocSpan}>
              <PageSection id="toc-menu">
                <div className="toc" id="toc">
                  <div id="toctitle">Content Types</div>
                  <ul>
                    {sidebarLinks.map((link, index) => (
                      <li key={index}>
                        <a href={`portfolio/learnmore#${link.id}`}>
                          {link.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </PageSection>
            </GridItem>

            {/* Main Content */}
            <GridItem span={mainContentSpan}>
              {sections.map((section, index) => (
                <PageSection key={index} id={section.id}>
                  <div style={{ textAlign: "center" }}>
                    <h2 className="section-title">{section.title}</h2>
                    {section.subtitle && (
                      <p className="section-subtitle">{section.subtitle}</p>
                    )}
                    {section.link && (
                      <a href={section.link} className="section-link">
                        Explore →
                      </a>
                    )}
                  </div>
                  <Grid hasGutter>
                    {section.cards.map((card, cardIndex) => (
                      <GridItem key={cardIndex} span={cardColSpan}>
                        <Card className="custom-card">
                          <CardHeader>
                            <CardHeaderMain>
                              <Brand
                                src={card.image}
                                alt={card.title}
                                className="card-image"
                              />
                            </CardHeaderMain>
                          </CardHeader>
                          <CardTitle>{card.title}</CardTitle>
                          <CardBody>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: card.description,
                              }}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    ))}
                  </Grid>

                  {/* Render Subsection if exists */}
                  {section.subSection && (
                    <PageSection
                      id={section.subSection.id}
                      className="subsection"
                    >
                      <h3 className="subsection-title">
                        {section.subSection.title}
                      </h3>
                      <Grid hasGutter>
                        {section.subSection.cards.map(
                          (subCard, subCardIndex) => (
                            <GridItem key={subCardIndex} span={cardColSpan}>
                              <Card className="custom-card">
                                <CardHeader>
                                  {subCard.image && ( // Render only if an image is defined
                                    <CardHeaderMain>
                                      <Brand
                                        src={subCard.image}
                                        alt={subCard.title}
                                        className="card-image"
                                      />
                                    </CardHeaderMain>
                                  )}
                                </CardHeader>
                                <CardTitle>{subCard.title}</CardTitle>
                                <CardBody>
                                  {/* Render HTML inside the description */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: subCard.description,
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </GridItem>
                          )
                        )}
                      </Grid>
                    </PageSection>
                  )}
                </PageSection>
              ))}
            </GridItem>
          </Grid>
        </div>
      </Page>
    );
  }
}

export { LearnMore };
