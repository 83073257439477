import * as React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, useLocation, useHistory } from 'react-router-dom';
import {
  Page,
  PageHeader,
  Nav,
  NavList,
  NavItem,
  SkipToContent,
  PageHeaderTools

} from '@patternfly/react-core';
import '@rhds/elements/rh-navigation-secondary/rh-navigation-secondary.js';
import '@rhds/elements/rh-navigation-secondary/rh-navigation-secondary-lightdom.css';

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  //var externalScript = "https://www.redhat.com/ma/dpal-staging.js";
  //const js_state = useExternalScript(externalScript);

  const [isMobileView, setIsMobileView] = React.useState(true);


  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  function openRedHatWindow(e) {
    window.open("https://www.redhat.com/en/technologies/all-products");
  }
  function openToolWindow(e) {
    window.open("/architect/portfolio/tool/index.html");
  }
  function openArchitectWindow(e) {
    window.open("https://www.redhat.com/architect/");
  }

  function openEBC(e) {
    window.open("https://pg.ebc.redhat.com");
  }

  function LogoImg() {
    const history = useHistory();
    
    function handleClick() {
      
    }
    return (
      <a href="https://www.redhat.com" target="_blank" rel="noopener noreferrer">
      <img src="https://www.redhat.com/architect/portfolio/images/logo_redhat.png" width="135" height="40" alt="Red Hat Official Logo" />
  </a>
  
    );
  }

  const headerTools = (
    <PageHeaderTools>


    </PageHeaderTools>
  );

  //enable architect  

  const menuContent = (

    <Nav variant="horizontal">
      <NavList>
        <NavItem key={0} itemId={0} isActive={false} href="/">
          <a href="https://www.redhat.com/en/technologies/all-products">Products</a>
        </NavItem>
        <NavItem key={1} itemId={1} isActive={false} href="/">
          <a href="https://www.redhat.com/en/solutions">Solutions</a>
        </NavItem>
        <NavItem key={1} itemId={1} isActive={false} href="/">
          <a href="https://www.redhat.com/en/services">Training & services</a>
        </NavItem>
        <NavItem key={1} itemId={1} isActive={false} href="/">
          <a href="https://www.redhat.com/en/resources">Resources</a>
        </NavItem>
        <NavItem key={1} itemId={1} isActive={false} href="/">
          <a href="https://www.redhat.com/en/partners">Partners</a>
        </NavItem>
        <NavItem key={1} itemId={1} isActive={false} href="/">
          <a href="https://www.redhat.com/en/about">About</a>
        </NavItem>
      </NavList>
    </Nav>
  );



  const SecondaryHeader = (
<rh-navigation-secondary role="navigation" color-palette="light" aria-label="Secondary Navigation for Red Hat Architecture Center">
  <a href="#" slot="logo" aria-label="Red Hat Architecture Center Home">
    Red Hat <br></br>Architecture Center
  </a>
  <ul slot="nav" aria-label="Navigation Menu">
    <li>
      <a href="/architect/portfolio/" aria-label="Browse the Architecture Center catalog for detailed resources and solutions">
        Catalog
      </a>
    </li>
    <li>
      <a href="/architect/portfolio/learnmore" aria-label="Explore learning materials and guides in the Architecture Center">
        Learn
      </a>
    </li>
    <li>
      <a href="https://www.redhat.com/architect/portfolio/tool/index.html" target="_blank" aria-label="Open the Architecture Center diagramming tool in a new tab">
        Diagramming Tool
      </a>
    </li>
  </ul>
</rh-navigation-secondary>


  );


  const Header = (
    <PageHeader
      logo={<LogoImg />}
      topNav={menuContent}
      headerTools={headerTools}
    />
  );




  const location = useLocation();

  const pageId = 'primary-app-container';

  const PageSkipToContent = (
    <SkipToContent onClick={(event) => {
      event.preventDefault();
      const primaryContentContainer = document.getElementById(pageId);
      primaryContentContainer && primaryContentContainer.focus();
    }} href={`#${pageId}`}>
      Skip to Content
    </SkipToContent>
  );

  function sendCustomEvent(evt: any) {
    if (document.createEvent && document.body.dispatchEvent) {
      var myEvent = document.createEvent('Event');
      myEvent.initEvent(evt, true, true); //can bubble, and is cancellable

      document.body.dispatchEvent(myEvent);
      console.log("New Event dispatched:[" + myEvent + "]");
      //@ts-ignore
    } else if (window.CustomEvent && document.body.dispatchEvent) {
      //@ts-ignore
      var event = new CustomEvent(evt,
        { bubbles: true, cancelable: true }
      );

      document.body.dispatchEvent(event);
      console.log("CustomEvent dispatched:[" + event + "]");
    }
  }

  useEffect(() => {
    // Call sendCustomEvent function when a new page is loaded:
    sendCustomEvent("pageBottom");
  }, [location]);



  return (
    <Page
      mainContainerId={pageId}
      header={Header}
      onPageResize={onPageResize}
      skipToContent={PageSkipToContent}>

      {SecondaryHeader}
      {children}

    </Page>
  );
}

export { AppLayout };
